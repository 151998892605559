<template>
  <v-dialog
    v-model="active"
    content-class="login-dialog"
    width="380px"
    transition="dialog-transition"
    :hide-overlay="hideOverlay"
    @click:outside="close">

    <v-tabs color="primary darken-1"
            background-color="var(--v-secondary-lighten3)"
            centered="centered"
            active-class="active-tab"
            hide-slider
            dark
            v-model="state"
            grow="grow">

      <v-tab class="font-500 font-16">
        {{ $t('common.signup') }}
      </v-tab>

      <v-tab class="font-500 font-16">
        {{ $t('common.login') }}
      </v-tab>

      <!-- this element is for v-tabs to refer to the tab w/o a real tab activator -->
      <v-tab v-show="false">
      </v-tab>

      <!-- signup -->
      <v-tab-item>
        <v-card elevation="0">
          <v-card-text>
            <Signup ref="signup"/>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- login -->
      <v-tab-item>
        <v-card elevation="0">
          <v-card-text>
            <Login ref="login"/>
            <v-row justify=center no-gutters class="margin-top-25">
              <v-btn color="accent darken-1" plain small @click="openForgotPassword">forgot password</v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- forgot password -->
      <v-tab-item>
        <v-card elevation="0">
          <v-card-text>
            <ForgotPassword ref="forgot"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-dialog>
</template>

<script type="text/javascript">
import Login from './login-form'
import Signup from './signup-form'
import ForgotPassword from './forgot-password'
import { mapGetters } from 'vuex'

export default {

  name: 'loginDialog',

  props: {
    hideOverlay: { // for rendering inside a stand alone page
      type: Boolean,
      default: false
    }
  },

  components: {
    Signup,
    Login,
    ForgotPassword
  },

  data () {
    return {
      active: false,
      state: 0,
      states: ['signup', 'login', 'forgot']
    }
  },

  computed: {
    ...mapGetters(['isBot'])
  },

  watch: {
    isBot () {
      if (this.isBot) this.close()
    }
  },

  methods: {
    openForgotPassword () {
      this.state = 2
    },

    open (state = 'signup') {
      if (this.isBot) return
      this.state = this.states.indexOf(state)
      this.active = true
      this.$root.$emit('loginDialog', 'opened')
    },

    close () {
      this.active = false

      // prevent undefined error in case tab wasn't changed
      if (this.$refs.signup) {
        this.$refs.signup.reset()
      }

      if (this.$refs.login) {
        this.$refs.login.reset()
      }

      this.$root.$emit('loginDialog', 'closed')
    }
  }
}

</script>

<style type="text/css" lang="scss">

  .login-dialog {

    .v-tab {
      // border-bottom: 1px solid var(--v-secondary-lighten5);

      &.active-tab {
        // border-color: transparent;
        background-color: #fff;
      }
    }

    .v-tabs-items {
      padding: 25px 35px;
    }

    .v-divider {
      border-color: var(--v-secondary-lighten5);
    }

    .errors {
      color: var(--v-error-base);
    }
  }

</style>
