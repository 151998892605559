const mixin = {

  props: {
    enabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      model: null,
      active: null
    }
  },

  computed: {
    appendIcon () {
      return this.active ? 'mdi-filter-outline' : 'mdi-filter'
    }
  },

  methods: {
    initValue () { return this.value }, // prevent no method error

    updateValue () {
      this.$emit('input', !this.active ? null : this.model)
    },

    toggle () {
      this.updateValue()
    }
  },

  created () {
    this.model = this.initValue()
    this.active = !!this.value || this.enabled // enabled if value is set by parent or forced with prop
    this.updateValue()
  }
}

export default mixin
