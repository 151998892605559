<template>
  <a :class="['social-button', classes()]" :href="this[this.for].url" target="_blank">
    <v-icon>{{this[this.for].icon}}</v-icon>
  </a>
</template>

<script>

export default {

  name: 'SocialButton',

  props: {
    for: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'medium'
    },
    paintOnHover: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      facebook: {
        icon: 'mdi-facebook',
        url: this.$config.facebook
      },
      twitter: {
        icon: 'mdi-twitter',
        url: this.$config.twitter
      },
      linkedin: {
        icon: 'mdi-linkedin',
        url: this.$config.linkedin
      },
      instagram: {
        icon: 'mdi-instagram',
        url: this.$config.instagram
      },
      youtube: {
        icon: 'mdi-youtube',
        url: this.$config.youtube
      }
    }
  },

  methods: {
    classes () {
      let classes = [
        this.size,
        this.for
      ]

      if (this.paintOnHover) {
        classes.push('paint-hover')
      }

      return classes
    }
  }
}
</script>

<style lang="scss" scoped>

  @mixin colors {

    &.facebook .v-icon {
      color: $fb-color;
    }

    &.twitter .v-icon {
      color: $tw-color;
    }

    &.linkedin .v-icon {
      color: $linkedin-color;
    }

    &.instagram .v-icon {
      color: $instagram-color;
    }

    &.youtube .v-icon {
      color: $youtube-color;
    }
  }

  .social-button {
    display: inline-block;
    margin: 2px;
    padding-bottom: 0.125em;
    cursor: pointer;
    text-align: center;
    transition: all .15s ease-in;
    overflow: hidden;

    &:hover {
      transform: scale(1.2);

      &.paint-hover {
        @include colors;
      }
    }

    &:not(.paint-hover) {
      @include colors;
    }

    &.small .v-icon {
      font-size: 24px;
    }

    &.medium .v-icon {
      font-size: 32px;
    }

    &.large .v-icon {
      margin: 3px;
      font-size: 48px;
    }

    .v-icon:before {
      /* prevent the icon from moving on scale */
      position: relative;
      top: .125em;
      height: 1em;
      width: 1em;
      color: inherit;
    }

    span {
      position: absolute;
      width: 0;
      height: 0;
      overflow: hidden;
    }
  }

</style>
