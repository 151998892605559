<template>
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn  :class="['wa-chatbot', classs]"
              color="var(--v-whatsapp)"
              :href="$config.whatsappChatBot"
              target="_blank"
              aria-label="whatsapp chat"
              depressed
              v-on="on"
              v-bind="[btnProps, attrs]">
        <v-icon :class="iconSize">{{iconName}}</v-icon>
        <slot/>
      </v-btn>
    </template>
    <span>{{$t('common.whatsapp_tooltip')}}</span>
  </v-tooltip>
</template>

<script>
export default {

  name: 'WaChatbot',

  props: {
    iconName: {
      type: String,
      default: 'mdi-whatsapp'
    },
    size: {
      type: String,
      default: 'large'
    },
    iconSize: {
      type: String,
      default: 'icon-2xl'
    },
    icon: Boolean,
    fab: String,
    width: String,
    height: String,
    dark: Boolean,
    outlined: Boolean,
    classs: String
  },

  data () {
    return {

    }
  },

  computed: {
    btnProps () {
      return {
        outlined: this.outlined,
        [this.size]: true,
        width: this.width,
        height: this.height,
        icon: this.icon,
        fab: this.fab,
        dark: this.dark
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
