<template>

  <v-footer color="secondary" dark class="main-footer">
    <v-container>
      <v-row no-gutters>

        <v-col lg=2 sm=3 cols=10 offset-sm=1 offset-lg=2 order-2 order-sm-0 class="text-center">
          <Logo full/>
        </v-col>

        <v-col lg=2 sm=3 cols=6 offset-sm=1 offset-lg=1 :class="$vuetify.breakpoint.smAndUp ? '' : 'padding-30'">
          <h5 class="font-18 mb-3">
            {{ $t('main_footer.contact_us') }}
          </h5>
          <div v-for="(contact, key) in contactItems" class="no-gutters" v-bind:key="key.toString()">
            <a :href="contact.url">
              <v-icon class="icon-md">{{contact.icon}}</v-icon>
              <span class="font-14 px-3">{{contact.text}}</span>
            </a>
          </div>
        </v-col>

        <v-col lg=2 sm=3 cols=6 offset-sm=1 offset-lg=0 :class="$vuetify.breakpoint.smAndUp ? '' : 'padding-30'">
          <h5 class="font-18 mb-3">
            {{ $t('main_footer.the_company') }}
          </h5>

          <v-layout class="social-links" column no-gutters>
            <router-link v-for="link in companyLiks" :to="link.route" :key="link.route">{{link.text}}</router-link>
          </v-layout>

          <Spacer height="20px"/>
          <div class="social-media">
            <v-row class="social-links" no-gutters>
              <social-button for="facebook" share size="small" paint-on-hover/>
              <social-button for="instagram" share size="small" paint-on-hover/>
              <social-button for="youtube" share size="small" paint-on-hover/>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <div class="recaptcha">
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>
    </v-container>
  </v-footer>

</template>

<script type="text/javascript">

import SocialButton from '@/common/components/social-button'

export default {
  name: 'MainFooter',

  components: {
    SocialButton
  },

  data () {
    return {
      contactItems: {
        // address: {
        //   icon: 'mdi-map-marker',
        //   text: this.$t('main_footer.address'),
        //   url: '#'
        // },
        phone: {
          icon: 'mdi-phone',
          text: this.$config.shortPhone,
          url: `tel:${this.$config.shortPhone}`
        },
        email: {
          icon: 'mdi-email-outline',
          text: this.$t('main_footer.send_a_message'),
          url: '/contact_us'
        }
      },
      newLaterEmail: '',
      companyLiks: [
        {
          text: this.$t('main_footer.links.about'),
          route: '/about'
        },
        {
          text: this.$t('main_footer.links.tos'),
          route: '/tos'
        },
        {
          text: this.$t('main_footer.links.faq'),
          route: '/faq'
        },
        {
          text: this.$t('main_footer.links.accessibility'),
          route: '/accessibility'
        }
      ]
    }
  }
}

</script>

<style lang="scss">

  .v-footer.main-footer {
    min-height: fit-content!important;
    padding: 45px 0 30px;

    @include xs-only {
      padding-top: 20px;
    }

    a {
      color: #fff;
      transition: all .15s;

      &:hover {
        color: var(--v-primary-lighten1);

        .v-icon {
          color: var(--v-primary-lighten1);
        }
      }
    }

    .logo img {
      max-height: 140px;
    }

    .container {
      max-width: 1200px;
    }

    .social-media {

      .social-links {
        padding-top: 5px;
      }

      a {
        margin-right: 5px;
      }
    }

    .recaptcha {
      position: absolute;
      left: 6px;
      bottom: 2px;
      direction: ltr;
      font-size: 12px;
      color: var(--v-bright-base);

      a {
        color: var(--v-bright-lighten1);
        text-decoration: underline;
      }
    }
  }
</style>
