<template>
  <div class="search-slider size-slider">

    <v-container>
      <div class=slider-title :class="{'theme--light v-input--is-disabled': active}">
        <span v-text="model[0]"/> -
        <span v-text="model[1]"/>
        {{ $t('search.size_units') }}
      </div>

      <v-row class="align-center" no-gutters>
        <v-switch dense v-model="active" @change="toggle" :ripple="false" hide-details/>
        <!-- <v-btn  icon
                @click="toggle"
                color="primary"
                aria-label="disable size">

          <v-icon :class="{enabled: !active}" color="primary darken-1">mdi-plus-circle-outline</v-icon>
        </v-btn> -->

        <v-range-slider v-model="model"
                        step="10"
                        min="60"
                        max="500"
                        height="40"
                        :disabled="!active"
                        track-color="primary lighen-1"
                        color="primary darken-1"
                        hide-details
                        @end="updateValue()"
                        @click="updateValue()"/>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import disabledMixin from '@/common/mixins/slider-disabled.js'

export default {
  name: 'SizeSlider',

  props: {
    value: [Array, String]
  },

  data () {
    return {
      defaultValue: [110, 150]
    }
  },

  mixins: [disabledMixin],

  methods: {
    initValue () {
      let initValue = this.defaultValue
      if (this.value) {
        initValue = Array.isArray(this.value) ? this.value : this.value.split(',')
      }
      return initValue
    }
  }
}
</script>

<style lang="scss" scoped>

  .size-slider {

    .slider-title {

      span {
        display: inline-block;
        width: 25px;
      }
    }
  }

</style>
