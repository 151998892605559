<template>
  <div class="main-header-holder">

    <v-navigation-drawer
      v-model="drawer"
      fixed
      color="bright"
      temporary
      right>

      <div class="main-nav">
        <div class="main-nav-user" :key="!!user">
          <template v-if="user">
            <span class="font-18">{{ $t('main_header.hello_user', {user_name: user.first_name}) }}</span>
            <spacer height="10px"/>
            <add-estate-btn width="90%"/>
          </template>

          <template v-else>
            <login-btn @click="login" outlined width="90%" color="primary darken-2"/>
            <spacer height="10px"/>
            <add-estate-btn @click="signup" width="90%" :to="null"/>
            <LoginDialog ref="loginDialog"/>
          </template>

          <spacer height="10px"/>
          <wa-chat width="90%" dark icon-size="icon-xl">
            <span class="font-15">&nbsp;{{$t('albi.wa_button_text')}}</span>
          </wa-chat>

          <spacer height="10px"/>
          <v-btn width="90%" large color="secondary" @click="openAlbi">
            <span class="font-15">{{$t('albi.button_text')}}</span>
          </v-btn>

          <template v-if="user">
            <spacer height="10px"/>
            <v-btn large color="primary" width="90%" :to="{name: 'profile'}">
              <v-icon class="icon-xl">mdi-account</v-icon>
              <span class="font-15">{{$t('main_header.edit_profile')}}</span>
            </v-btn>
          </template>
        </div>

        <spacer height="10px"/>

        <router-link :to="{name: 'home'}">{{$t('main_header.home')}}</router-link>
        <router-link to="/about">{{$t('main_header.about')}}</router-link>
        <!-- <router-link to="/magazine">{{$t('main_header.rbns_magazine')}}</router-link> -->
        <router-link :to="{name: 'contactUs'}">{{$t('main_header.contact_us')}}</router-link>
        <router-link :to="{name: 'workWithUs'}">{{$t('main_header.work_with_us')}}</router-link>
        <router-link :to="{name: 'estateGuide'}" v-if="!user">{{$t('main_header.estate_guide')}}</router-link>
        <router-link :to="{name: 'community'}" v-if="!user">{{$t('main_header.community')}}</router-link>

        <spacer height="20px"/>

        <span class="services-h">{{ $t('main_header.out_services') }}</span>
        <v-divider/>

        <!-- <router-link :to="{name: 'greece'}">{{$t('main_header.greece')}}</router-link> -->
        <!-- <router-link :to="{name: 'cyprus'}">{{$t('main_header.cyprus')}}</router-link> -->
        <router-link :to="{name: 'tama'}">{{ $t('main_header.tama') }}</router-link>
        <!-- <router-link :to="{name: 'familyEconomy'}">{{ $t('main_header.family_economy') }}</router-link> -->
        <router-link :to="{name: 'mortgage'}">{{ $t('main_header.mortgage') }}</router-link>
        <!-- <router-link :to="{name: 'buy'}">{{ $t('main_header.buy') }}</router-link> -->
        <!-- <router-link :to="{name: 'sale'}">{{ $t('main_header.sale') }}</router-link> -->

        <spacer height="20px"/>

        <template v-if="user">
          <v-divider/>
          <a @click.stop="logout">{{$t('common.logout')}}</a>
        </template>
      </div>
    </v-navigation-drawer>

    <v-app-bar hide-on-scroll
               fixed
               height="70"
               scroll-threshold="70">

      <v-row align="center" no-gutters>
        <v-col cols=5>
          <v-row align=center no-gutters>
            <v-app-bar-nav-icon @click="drawer = !drawer" aria-label="main menu" color="bright" large/>

            <template v-if="$vuetify.breakpoint.smAndUp">
              <spacer width="15px"/>
              <!-- Signup / Add property -->
              <add-estate-btn class="puls" @click="signup" :to="user ? {name: 'new_estate'} : null" height="38"/>
            </template>
          </v-row>
        </v-col>

        <v-col align="center">
          <span class="logo-text" v-if="$vuetify.breakpoint.smAndUp"><span class="font-16">R</span>bns</span>
          <Logo/>
        </v-col>

        <v-col cols=5 align="end" :class="['main-header-search', {'search-mode': searchMode}]">
          <v-row  justify="end"
                  no-gutters
                  v-if="!$route.meta.hideHeaderSearch"
                  v-click-outside="closeSearch">
            <v-btn color="primary" aria-label="search" @click="openSearch" class="main-header-search-btn" small height=38>
              <v-icon class="icon-lg">mdi-home-search-outline</v-icon>
            </v-btn>
            <search text-only ref="search" @search="closeSearch"/>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>
  </div>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex'
import LoginDialog from '@/app/modules/user/components/login-dialog'
import Search from '@/app/modules/estates/components/search/quick-search'
import LoginBtn from '@/app/common/components/login-btn'
import AddEstateBtn from '@/app/common/components/add-estate-btn'
import WaChat from '@/app/common/components/wa-chatbot'

export default {
  name: 'MobileHeader',

  components: {
    LoginDialog,
    Search,
    LoginBtn,
    WaChat,
    AddEstateBtn
  },

  data () {
    return {
      extended: false,
      drawer: false,
      searchMode: false,
      availableDeals: [
        { text: this.$t(`search.sale`), value: 'sale' },
        { text: this.$t(`search.rent`), value: 'rent' }
      ],
      searchDealType: 'sale',
      followUpAction: null
    }
  },

  computed: {
    ...mapGetters('user', ['user']),
    ...mapGetters(['locale'])
  },

  methods: {
    ...mapActions('user', ['logout']),
    // ...mapActions(['setLocale']),

    login () {
      this.$refs.loginDialog.open('login')
      this.$mixpanel.track('login', { action: 'click', source: 'header_nav' })
    },

    signup () {
      if (this.user) return
      this.$router.push({ name: 'estateGuide' })
      // this.followUpAction = this.redirectToAddEstate
      // this.$refs.loginDialog.open('signup')
      // this.$mixpanel.track('signup', { action: 'click', source: 'header_add_estate' })
    },

    toggleMenu () {
      this.extended = !this.extended
    },

    openSearch (e) {
      this.searchMode = true
      this.$refs.search.focus()
    },

    closeSearch (e) {
      this.searchMode = false
    },

    redirectToAddEstate () {
      this.$router.push({ name: 'new_estate' })
    },

    openAlbi () {
      this.drawer = false
      this.$root.$emit('openAlbi', { refering_feature: 'header_nav' })
    }
  },

  mounted () {
    if (this.$route.query.action && !this.user) {
      try {
        this[this.$route.query.action]()
      } catch (e) {
        console.error(`"${this.$route.query.action}" action dosen't exist`)
      }
    }

    this.$root.$on('loginDialog', (action) => {
      if (action === 'closed') this.followUpAction = null
    })

    this.$root.$on('signup', () => {
      if (typeof this.followUpAction === 'function') this.followUpAction()
    })

    this.$root.$on('login', () => {
      if (typeof this.followUpAction === 'function') this.followUpAction()
    })
  }
}

</script>

<style lang="scss">

  .main-header {

    &-holder {

      .v-app-bar {
        background: linear-gradient(to bottom, var(--v-secondary-base) 5%, transparent) !important;
        box-shadow: none !important;

        .v-btn.v-btn--icon {
          text-shadow: 1px 1px 2px var(--v-secondary-base);
        }
      }

      .add-estate,
      .square-icon-btn,
      .v-app-bar__nav-icon {

        &:hover {
          color: var(--v-primary-lighten1) !important;
        }
      }

      .logo {

        img {
          height: 42px;
        }

        @include xs-only {
          display: block;
          height: 42px;
        }
      }

      .logo-text {
        position: relative;
        top: -6px;
        color: #fff;
        font-size: $font-xs;
        letter-spacing: 1.5px;
      }

      .main-nav {
        padding: 20px 10px;

        &-user {
          align-items: center;
          display: flex;
          flex-direction: column;
        }

        > a {
          display: flex;
          padding: 5px 3px;
          font-size: $font-xs;
          font-weight: 600;
          line-height: 23px;
          text-transform: uppercase;
          letter-spacing: .2em;
          color: var(--v-secondary-lighten1);

          &:hover,
          &.active {
            color: var(--v-anchor-base);
          }
        }
      }

      .logo-row {
        margin-bottom: 20px;
        background-color: var(--v-secondary-base);
      }

      .services-h {
        font-size: $font-md;
        padding: 5px 3px;
      }
    }

    &-search {
      position: relative;

      form {
        transition: right .2s linear, max-width .2s linear, opacity .2s linear .05s;
      }

      form {
        position: absolute;
        left: 0;
        top: 0;
        right: 100%;
        max-width: 0;
        opacity: 0;
        overflow: hidden;
      }

      &.search-mode {

        form {
          opacity: 1;
          overflow: initial;

          @include xs-only {
            right: calc(-100vw + 100% + 32px);
            max-width: none;
          }

          @include sm-and-up {
            max-width: 100%;
            right: 15px;
          }
        }
      }
    }
  }

</style>
