<template>
  <v-row no-gutters class="features-search">
    <v-col v-for="(v, f) in features" :cols="cols" :md="md" :lg="lg" v-bind:key="f">

      <v-checkbox v-model="features[f]"
                  @change='updateValue'
                  color="primary"
                  :ripple="false"
                  hide-details>

        <template v-slot:label>
          {{$t(`estates.props.${f}`)}}
        </template>
      </v-checkbox>

    </v-col>
  </v-row>
</template>

<script>
import estateFeaeturs from '@/common/mixins/estates/estate-features-mixin'

export default {
  name: 'FeatuesSearch',

  props: {
    value: {
      type: [Array, String],
      default: () => []
    },

    cols: {
      type: Number,
      default: 6
    },

    md: {
      type: Number,
      default: 4
    },

    lg: {
      type: Number,
      default: 3
    }
  },

  mixins: [estateFeaeturs],

  methods: {
    updateValue () {
      this.$emit('input', this.activeFeatures)
    }
  },

  created () {
    this.activeFeatures = Array.isArray(this.value) ? this.value : this.value.split(',')
  }
}
</script>

<style lang="scss" >
</style>
