<template>
  <v-form ref="form" @submit.prevent="submit">
    <div class="errors padding-bottom-20 font-18" v-if="serverErrors">
      {{serverErrors}}
    </div>

    <v-text-field
      v-model="email"
      outlined
      dense
      color="primary"
      :label="$t('common.email')"
      :rules="[rules.required, rules.email]" />

    <v-text-field
      v-model="password"
      outlined
      dense
      color="primary"
      :label="$t('common.password')"
      type= "password"
      :rules="[rules.required]" />

    <!-- <spacer height="20px"/> -->
    <v-btn color="primary" block type="submit">{{ $t('common.login')}}</v-btn>
  </v-form>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex'
import rules from '@/common/mixins/validations-rules-mixin.js'

export default {
  name: 'loginForm',

  data () {
    return {
      email: '',
      password: '',
      serverErrors: ''
    }
  },

  mixins: [rules],

  computed: {
    ...mapGetters('user', ['user'])
  },

  methods: {
    ...mapActions('user', ['login', 'logout']),

    async submit () {
      if (this.$refs.form.validate()) {
        var email = this.email
        var password = this.password
        try {
          await this.login({ email, password })
          this.$root.$emit('login')
          this.$mixpanel.track('login', { action: 'submit', user: this.user.id })
        } catch (e) {
          this.serverErrors = this.$t('common.login_error_message')
          console.error(e)
          console.error(e.status)
          if (e.status === 401 || e.status === 403) {
            await this.logout()
          }
        }
      }
    },

    reset () {
      this.$refs.form.resetValidation()
      this.$refs.form.reset()
    }
  },

  created () {
    this.$mixpanel.track('login', { action: 'load' })
  }
}

</script>

<style type="text/css" lang="scss">
</style>
