<template>
  <aside :class="['albi', {'high-view': highView}]">
    <!-- <div class="albi-activator-container" v-show="!$route.meta.hideAlbi">
      <v-btn large :class="['albi-activator', {puls}]" color="secondary" @click="open({refering_feature: 'albi_button'})">{{$t('albi.button_text')}}</v-btn>
    </div> -->

    <v-form ref="form" :class="['albi-form', {open: isOpen}]">
      <div class="albi-logo">
        <img src="@/assets/images/logo_3.png"/>
      </div>
      <v-btn color="secondary lighten-3" icon class="albi-close" @click="close">
        <v-icon color="">mdi-close-circle</v-icon>
      </v-btn>

      <v-stepper v-model="step" :height="$vuetify.breakpoint.smAndUp ? '550' : '100%'">
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-layout column fill-height>
              <h6 v-html="$t('albi.free_text.title')"/>
              <p>{{ $t('albi.free_text.description') }}</p>
              <free-search v-model="freeSearchParams" no-button :label="$t('albi.free_text.field_placeholder')"/>
              <div class="d-flex"/>
              <v-btn color="secondary" @click="next">{{$t('common.next')}}</v-btn>
            </v-layout>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-layout column fill-height>
              <h6>
                <v-icon @click="back" class="icon-lg" color="secondary">mdi-arrow-right</v-icon>
                {{ $t('albi.types.title') }}
              </h6>
              <types v-model="typesFilter" :cols="6" :md="6" :lg="6"/>
              <div class="d-flex"/>
              <v-btn color="secondary" @click="next">{{$t('common.next')}}</v-btn>
            </v-layout>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-layout column fill-height>
              <h6>
                <v-icon @click="back" class="icon-lg" color="secondary">mdi-arrow-right</v-icon>
                {{ $t('albi.ranges.title') }}
              </h6>
              <price v-model="price"/>
              <size v-model="sizeRange"/>
              <rooms v-model="rooms"/>
              <div class="d-flex"/>
              <v-btn color="secondary" @click="next">{{$t('common.next')}}</v-btn>
            </v-layout>
          </v-stepper-content>

          <v-stepper-content step="4">
            <v-layout column fill-height>
              <h6>
                <v-icon @click="back" class="icon-lg" color="secondary">mdi-arrow-right</v-icon>
                {{ $t('albi.features.title') }}
              </h6>
              <features v-model="featuresFilter" :cols="6" :md="6" :lg="6"/>
              <div class="d-flex"/>
              <v-btn color="secondary" @click="next">{{$t('common.next')}}</v-btn>
            </v-layout>
          </v-stepper-content>

          <v-stepper-content step="5">
            <v-layout column fill-height>
              <h6>
                <v-icon @click="back" class="icon-lg" color="secondary">mdi-arrow-right</v-icon>
                {{ $t('albi.notifications.title') }}
              </h6>
              <spacer height="20px"/>
              <div>
                <v-text-field :disabled="!!existingClient" v-model="notificationsClient.first_name" :label="$t('albi.notifications.first_name')" outlined dense :rules="[rules.required]" validate-on-blur/>
                <v-text-field :disabled="!!existingClient" v-model="notificationsClient.last_name" :label="$t('albi.notifications.last_name')" outlined dense/>
                <v-text-field :disabled="!!existingClient" v-model="notificationsClient.email" :label="$t('albi.notifications.email')" outlined dense :rules="[rules.email]" validate-on-blur/>
                <v-text-field :disabled="!!existingClient" v-model="notificationsClient.mobile_phone" :label="$t('albi.notifications.mobile')" outlined dense :rules="[rules.required, rules.mobile]" validate-on-blur @change="searchClient"/>
                <v-textarea :disabled="!!existingClient" v-model="notificationsClient.notes[0]" :placeholder="$t('albi.notifications.notes')" outlined dense height="65" no-resize/>
              </div>
              <div class="d-flex"/>
              <small>{{$t('albi.notifications.confirmation')}}</small>
              <v-btn color="secondary" @click="runSearch">{{$t('albi.search')}}</v-btn>
            </v-layout>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-form>

    <v-dialog v-model="existingClientDialog" :max-width="$vuetify.breakpoint.smAndUp ? '530' : '95%'" @click:outside="resetClient" >
      <v-card class="padding-15">
        <v-card-title class="text-center">
          {{$t('albi.exiting_client_dialog.title')}}
        </v-card-title>
        <v-card-actions>
          <v-row>
            <v-col col-sm="6">
              <v-btn block color="primary" @click="setExistUser">
                {{$t('albi.exiting_client_dialog.button_keep')}}
              </v-btn>
            </v-col>
            <v-col col-sm="6">
              <v-btn block text color="secondary" outlined @click="resetClient">
                {{$t('albi.exiting_client_dialog.button_change')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </aside>
</template>

<script>
import stepperMixin from '@/common/mixins/stepper-mixin.js'
import FreeSearch from '@/app/modules/estates/components/search/search-components/free-search'
import Price from '@/app/modules/estates/components/search/search-components/price-slider'
import Size from '@/app/modules/estates/components/search/search-components/size-slider'
import Rooms from '@/app/modules/estates/components/search/search-components/rooms-slider'
import Types from '@/app/modules/estates/components/search/search-components/types-search'
import Features from '@/app/modules/estates/components/search/search-components/features-search'
import notificationsService from '@/app/common/services/notifications-service'
import rules from '@/common/mixins/validations-rules-mixin.js'

export default {
  name: 'Albi',

  components: {
    FreeSearch,
    Price,
    Size,
    Rooms,
    Types,
    Features
  },

  mixins: [stepperMixin, rules],

  data () {
    return {
      // viewed: false,
      puls: false,
      highView: false,
      step: 1,
      isOpen: false,
      freeSearchParams: {
        freeText: '',
        dealType: 'sale'
      },
      typesFilter: [],
      price: 2500000,
      sizeRange: null,
      rooms: null,
      featuresFilter: [],
      notificationsClient: {
        first_name: null,
        last_name: null,
        mobile_phone: null,
        email: null,
        notes: ['']
      },
      existingClient: false,
      existingClientDialog: false // ,
      // currentLocation: null
    }
  },

  watch: {
    existingClient (existingClient) {
      if (existingClient) {
        this.existingClientDialog = true
      }
    }
  },

  methods: {
    // getCurrentlocation () {
    //   console.log(navigator.geolocation.getCurrentPosition)
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition((position) => {
    //       console.log(position)
    //       this.currentLocation = {
    //         lat: position.coords.latitude,
    //         lng: position.coords.longitude
    //       }
    //     })
    //   }
    // },

    // required () {
    //   return this.notificationsClient.email || this.notificationsClient.mobile_phone || 'phone or email is required'
    // },

    async searchClient () {
      try {
        this.existingClient = await notificationsService.getByMobilePhone(this.notificationsClient.mobile_phone)
      } catch (e) { }
    },

    setExistUser () {
      this.notificationsClient = { ...this.notificationsClient, ...this.existingClient }
      this.existingClientDialog = false
    },

    resetClient () {
      this.notificationsClient.mobile_phone = null
      this.existingClient = false
      this.existingClientDialog = false
    },

    open (event) {
      // this.isOpen = this.viewed = true
      this.isOpen = true
      this.puls = false
      this.$mixpanel.track('albi', {
        action: 'open',
        ...event
      })
    },

    close () {
      this.isOpen = false
      this.$mixpanel.track('albi', {
        action: 'close'
      })
    },

    next () {
      this.step++
      this.$mixpanel.track('albi', {
        action: 'next',
        step: this.step
      })
    },

    back () {
      this.step--
      this.$mixpanel.track('albi', {
        action: 'back'
      })
    },

    reset () {
      this.step = 1
      this.freeSearchParams = {
        freeText: '',
        dealType: 'sale'
      }
      this.typesFilter = []
      this.price = 2500000
      this.sizeRange = null
      this.rooms = null
      this.featuresFilter = []
    },

    query () {
      let query = ''

      query = `${query}deal_type=${this.freeSearchParams.dealType}&` // deal_type alyaws has a value

      if (this.freeSearchParams.freeText) {
        query = `${query}search=${this.freeSearchParams.freeText}&`
      }

      if (this.price) {
        query = `${query}price=${this.price}&`
      }

      if (this.sizeRange) {
        query = `${query}size=${this.sizeRange}&`
      }

      if (this.rooms) {
        query = `${query}rooms=${this.rooms}&`
      }

      if (this.typesFilter) {
        query = `${query}types=${this.typesFilter.toString()}&`
      }

      if (this.featuresFilter) {
        query = `${query}features=${this.featuresFilter.toString()}&`
      }

      return query
    },

    runSearch () {
      if (this.$refs.form.validate()) {
        const query = this.query()
        // track need to be before routing to search for the "page" property
        this.$mixpanel.track('albi', {
          page: this.$route.name,
          action: 'submit',
          price: this.price || false,
          deal_type: this.freeSearchParams.dealType,
          search_text: this.freeSearchParams.freeText || false,
          query: query || false,
          client: this.notificationsClient
        })

        if (!this.existingClient) {
          this.saveNotification(query)
        }
        this.$router.push(`/estates/search?${query}`)
        this.close()
      }
    },

    saveNotification (query) {
      const notification = {
        ...this.notificationsClient,
        estate_searches: this.searchQueryToObject(query)
      }
      notificationsService.saveNotification(notification)
    }
  },

  created () {
    this.reset()

    if (this.$route.name === 'estate' && this.$vuetify.breakpoint.xsOnly) {
      this.highView = true
    }

    this.$root.$on('intersect', (component) => {
      this.highView = component === 'HomeHeader'
    })
    // this.getCurrentlocation()

    this.$root.$on('openAlbi', (event) => {
      this.open(event)
    })

    this.$root.$on('closeAlbi', () => {
      this.close()
    })

    // setTimeout(() => {
    //   if (this.$vuetify.breakpoint.xsOnly) {
    //     this.puls = true
    //   } else {
    //     if (!this.viewed) this.open({ refering_feature: 'albi_timeout' })
    //   }
    // }, 30000)
  }
}
</script>

<style lang="scss">

  .albi {
    position: fixed;
    right: 0;
    width: 100%;
    bottom: 25px;
    z-index: 9;
    transition: all .35s ease-out;

    .albi {
      // no css here!

      &-activator-container {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
      }

      &-activator {
        font-family: Levin;
      }

      &-close {
        position: absolute;
        top: -18px;
        z-index: 1;
        right: -18px;

        .v-icon {
          background: #fff;
          border-radius: 50%;
        }
      }

      &-logo {
        position: absolute;
        top: -20px;
        width: 42px;
        height: 42px;
        z-index: 2;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 50%;

        img {
          position: absolute;
          top: 0px;
          right: 0px;
          width: 44px;
          height: 44px;
          padding: 2px;
          background: var(--v-secondary-lighten2);
          border-radius: 50%;
        }

        &:before {
          content: "";
          position: absolute;
          top: 1px;
          width: 44px;
          height: 19px;
          box-shadow: 0px -1px 5px 0px var(--v-primary-darken1);
          border-radius: 44px 44px 0 0;
        }
      }
    }

    .v-sheet.v-stepper:not(.v-sheet--outlined) {
      box-shadow: 0px 1px 5px 0px  var(--v-primary-darken1);
    }

    &-form {
      position: fixed;
      width: 93%;
      height: 96%;
      bottom: -120vh;
      right: 0;
      left: 0;
      margin: auto;
      transition: all .2s ease-out;

      .v-stepper {
        background: rgba(255, 255, 255, .97);
      }

      .v-stepper,
      .v-stepper__items,
      .v-stepper__content,
      .v-stepper__wrapper {
        height: 100%;

        .d-flex {
          flex: auto;
        }
      }

      h6 {
        margin: 10px 0;
        font-size: $font-md;
      }

      small {
        padding: 5px;
        font-size: $font-xxs;
        line-height: 1.2;
      }

      &.open {
        bottom: 3px;
      }
    }

    @include xs-only {

      &.high-view {
        bottom: 65px;

        .albi-activator-container {
          left: 0;
          right: 0;
        }
      }

      .albi {

        &-activator-container {
          left: 25px;
          right: auto;
        }
      }
    }

    @include sm-and-up {
      right: 100px;
      bottom: 25px;
      width: auto;

      &-form {
        position: absolute;
        width: 350px;
        height: 550px;
        bottom: -600px;
        right: -2px;

        &.open {
          bottom: -2px;
        }
      }
    }
  }
</style>
