
export const types = {
  // upper/lowercase must be as it defined in db
  apartment: false,
  duplex: false,
  residentialUnit: false,
  penthouse: false,
  minipenthouse: false,
  loftStudio: false,
  gardenApartment: false,
  assistedLiving: false,
  basementorParterre: false,
  house: false,
  twoFamilyDwelling: false,
  threestory: false,
  farm: false,
  yards: false,
  building: false,
  complex: false // ,
  // building: false,
  // parking: false,
  // holidayCottage: false
}

const activeTypes = {
  get () {
    return Object.keys(this.types).reduce((acc, k) => {
      if (this.types[k]) {
        acc.push(k)
      }
      return acc
    }, [])
  },
  set (val) {
    Object.keys(this.types).forEach((k) => {
      this.types[k] = val.indexOf(k) > -1
    })
  }
}

export default {
  data () {
    return {
      types
    }
  },

  computed: {
    activeTypes
  }
}
