import { features } from '@/app/modules/estates/utils/estate-props'

const activeFeatures = {
  get () {
    return Object.keys(this.features).reduce((acc, k) => {
      if (this.features[k]) {
        acc.push(k)
      }
      return acc
    }, [])
  },
  set (val) {
    Object.keys(this.features).forEach((k) => {
      this.features[k] = val.indexOf(k) > -1
    })
  }
}

export default {
  data () {
    return {
      features
    }
  },

  computed: {
    activeFeatures
  }
}
