<template>
  <v-app id="app" :class="{mobile: $vuetify.breakpoint.mobile}">

    <main-header/>

    <v-layout column>
      <router-view/>
    </v-layout>

    <MainFooter v-if="!$route.meta.hideFooter"/>

    <div class="quick-actions">
      <v-tooltip left v-if="$route.name !== 'new_estate'">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            class="add-estate-fab"
            :to="user ? { name: 'new_estate' } : null"
            @click="login"
            fab :height="btnSize"
            :width="btnSize"
            depressed
          >
            <v-icon class="icon-xl">mdi-home-plus-outline</v-icon>
          </v-btn>
          <LoginDialog ref="loginDialog"/>
        </template>
        <span>{{$t('main_header.add_property')}}</span>
      </v-tooltip>
      <wa-chat
        classs="main-wa-chatbot"
        fab
        :width="btnSize"
        :height="btnSize"
        dark
        :icon-size="$vuetify.breakpoint.smAndDown ? 'xl' : '2xl'"
      />
    </div>

    <cookies-policy-message/>
    <albi/>
  </v-app>
</template>

<script type="text/javascript">
import MainHeader from './common/components/main-header'
import MainFooter from './common/components/main-footer'
import CookiesPolicyMessage from './common/components/cookies-policy-message'
import Albi from './common/components/albi'
import WaChat from './common/components/wa-chatbot'
import { mapActions, mapGetters } from 'vuex'
import { postRecaptchaToken } from '@/common/services/api-service'
import LoginDialog from '@/app/modules/user/components/login-dialog'

export default {

  name: 'Rbns',

  components: {
    MainHeader,
    MainFooter,
    CookiesPolicyMessage,
    WaChat,
    Albi,
    LoginDialog
  },

  // data () {
  //   return {
  //   }
  // },

  computed: {
    ...mapGetters('user', ['user']),

    btnSize () {
      return this.$vuetify.breakpoint.smAndUp ? '54px' : '50px'
    }
  },

  methods: {
    ...mapActions(['setBot', 'setLocale']),

    login () {
      if (this.user) return
      this.$refs.loginDialog.open('signup')
      this.$mixpanel.track('signup', { action: 'click', source: 'add_estate_fab' })
    }
  },

  async created () {
    this.setLocale(this.$config.locale.lang)
    await this.$recaptchaLoaded()
    const token = await this.$recaptcha('page_load')
    const res = await postRecaptchaToken(token)
    this.setBot(res.data.score < 0.5)
  }
}

</script>

<style lang="scss">

.quick-actions {
  position: fixed;
  right: 10px;
  bottom: 180px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1;

  .v-btn {
    border: 3px solid var(--v-bright-base)!important;
  }

  .add-estate-fab {
    // for ovewriting v-btn primaty class border!
    background-color: var(--v-primary-base)!important;
  }
}

</style>
