<template>
  <v-form ref="form" @submit.prevent="submit">
    <h4 class="margin-bottom-15">{{ $t(`user.forgot_password.title`) }}</h4>
    <v-text-field
      v-model="email"
      outlined
      dense
      color="primary"
      :label="$t('common.email')"
      :error-messages="serverErrors"
      :rules="[rules.required]" />

    <!-- <spacer height="20px"/> -->
    <v-btn color="primary" block type="submit">{{ $t('common.submit')}}</v-btn>

    <v-snackbar v-model="snackbar" :color="snackbarColor">
      <div v-html="snackbarMessage"/>
    </v-snackbar>
  </v-form>
</template>

<script>
import UserService from '../user-service'
import rules from '@/common/mixins/validations-rules-mixin.js'

export default {

  name: 'ForgotPassword',

  data () {
    return {
      email: '',
      snackbarMessage: '',
      snackbarColor: '',
      snackbar: false,
      serverErrors: null
    }
  },

  mixins: [rules],

  methods: {
    showSnackbar (type) {
      this.snackbarMessage = this.$t(`user.forgot_password.${type}_message`, { email: this.email })
      this.snackbarColor = type
      this.snackbar = true
    },

    async submit () {
      if (this.$refs.form.validate()) {
        this.serverErrors = null
        var email = this.email
        const exist = await UserService.isEmailExist(email)
        if (exist) {
          try {
            await UserService.resetPasswordRequest(email)
            this.showSnackbar('success')
            this.$root.$emit('resetPasswordRequest')
          } catch (e) {
            this.showSnackbar('error')
            console.error(e)
          }
        } else {
          this.serverErrors = this.$t(`user.forgot_password.email_not_exist`)
        }
      }
    }
  }
}
</script>

<style lang="scss" >
</style>
