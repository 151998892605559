import { Api } from '@/common/services/api-service'

const baseUrl = '/assistentSearch'

const deleteNotification = async (id) => {
  if (!id) {
    console.error('can\'t delete notification without ID')
    return
  }
  try {
    const res = await Api.delete(`${baseUrl}/${id}`)
    return res
  } catch (e) {
    console.error(e)
    throw e
  }
}

const saveNotification = async (notification) => {
  try {
    const res = await Api.post(baseUrl, notification)
    return res
  } catch (e) {
    console.error(e)
  }
}

const getByMobilePhone = async (phone) => {
  try {
    const res = await Api.get(`${baseUrl}/byMobilePhone/${phone}`)
    return res
  } catch (e) {
    console.error(e)
  }
}

export default {
  deleteNotification,
  saveNotification,
  getByMobilePhone
}
