<template>
  <div class="search-slider rooms-slider">

    <v-container>
      <div class="slider-title" :class="{'theme--light v-input--is-disabled': active}">
        {{ model }} {{ $t('search.bedrooms') }}
      </div>

      <v-row class="align-center" no-gutters>
        <v-switch dense v-model="active" @change="toggle" :ripple="false" hide-details/>
  <!--       <v-btn  icon
                @click="toggle"
                color="primary"
                aria-label="disable rooms">

          <v-icon :class="{enabled: !active}" color="primary darken-1">mdi-plus-circle-outline</v-icon>
        </v-btn>
   -->
        <v-slider v-model="model"
                  height="40"
                  step="1"
                  min="1"
                  max="10"
                  ticks
                  :disabled="!active"
                  track-color="primary lighen-1"
                  color="primary darken-1"
                  @end="updateValue()"
                  @click="updateValue()"
                  hide-details/>

      </v-row>
    </v-container>
  </div>
</template>

<script>
import disabledMixin from '@/common/mixins/slider-disabled.js'

export default {
  name: 'RoomsSlider',

  props: {
    value: [String, Number]
  },

  data () {
    return {
      defaultValue: 4
    }
  },

  mixins: [disabledMixin],

  methods: {
    initValue () {
      return this.value || this.defaultValue
    }
  }
}

</script>

<style lang="scss" scope>

</style>
