<template>
  <v-form class="quick-search" @submit.prevent="runSearch">
    <FreeSearch v-model="freeSearchParams" @input="onInput" @focus="onFocus" @blur="onBlur" ref="search">
      <template v-slot:extend v-if="!textOnly">

        <Spacer width="20px"/>

        <v-col sm=4>
          <Price v-model="price" />
        </v-col>
      </template>
    </FreeSearch>

  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import estateMixin from '@/common/mixins/estates/estate-features-mixin'
import FreeSearch from './search-components/free-search'
import Price from './search-components/price-slider'
// import Size from './search-components/size-slider'

export default {
  name: 'QuickSearch',

  props: {
    textOnly: Boolean
  },

  components: {
    Price,
    // Size,
    FreeSearch
  },

  data () {
    return {
      freeSearchParams: {
        freeText: this.$route.query.search || '',
        dealType: this.$route.query.deal_type || 'sale'
      },
      price: this.$route.query.price || null
    }
  },

  mixins: [estateMixin],

  computed: {
    ...mapGetters(['locale']),

    query () {
      let query = ''

      if (this.freeSearchParams.freeText) {
        query = `${query}search=${this.freeSearchParams.freeText}&`
      }

      if (this.price) {
        query = `${query}price=${this.price}&`
      }

      query = `${query}deal_type=${this.freeSearchParams.dealType}` // deal_type alyaws has a value

      return query
    }
  },

  methods: {
    runSearch () {
      // track need to be before routing to search for the "page" property
      this.$mixpanel.track('quick_search', {
        page: this.$route.name,
        price: this.price || false,
        deal_type: this.freeSearchParams.dealType,
        search_text: this.freeSearchParams.freeText || false,
        query: this.query || false
      })
      this.$router.push(`/estates/search?${this.query}`)
      this.$emit('search', this.query)
    },

    onInput ($event) {
      this.$emit('input', $event)
    },

    onFocus ($event) {
      this.$emit('focus', $event)
    },

    onBlur ($event) {
      this.$emit('blur', $event)
    },

    focus () {
      this.$refs.search.focus()
    }
  },

  created () {
    this.features.safetyroom = true
  }
}
</script>

<style lang="scss">

  .quick-search {

  }

</style>
