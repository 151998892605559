<template>
  <v-btn @click.stop="$emit('click')" aria-label="login" v-bind="btnProps">
    <v-icon class="icon-xl">{{icon}}</v-icon>
    <spacer width="10px" v-if="icon && text"/>
    <span class="font-15">{{ text }}</span>
  </v-btn>
</template>

<script>
export default {

  name: 'LoginBtn',

  props: {
    icon: {
      type: String,
      default: 'mdi-account-plus'
    },
    text: {
      type: String,
      default: function () { return this.$t('common.login') }
    },
    size: {
      type: String,
      default: 'large'
    },
    color: {
      type: String,
      default: 'primary'
    },
    width: String,
    height: String,
    outlined: Boolean
  },

  computed: {
    btnProps () {
      return {
        outlined: this.outlined,
        [this.size]: true,
        color: this.color,
        width: this.width,
        height: this.height
      }
    }
  }
}
</script>
