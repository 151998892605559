<template>
  <v-form ref="form" @submit.prevent="submit">
    <div class="errors padding-bottom-20 font-18" v-if="serverErrors">
      {{serverErrors}}
    </div>
    <v-text-field
      v-model="firstName"
      name="firstName"
      outlined
      dense
      color="primary"
      :label="$t('common.first_name')"
      required
      :rules="[rules.required]" />

    <v-text-field
      v-model="lastName"
      outlined
      dense
      color="primary"
      :label="$t('common.last_name')"
      required
      :rules="[rules.required]" />

    <v-text-field
      v-model="email"
      outlined
      dense
      color="primary"
      :label="$t('common.email')"
      required
      :rules="[rules.required]" />

    <v-text-field
      v-model="mobile"
      outlined
      dense
      color="primary"
      :label="$t('common.mobile')"
      required
      :rules="[rules.required, rules.mobile]" />

    <v-text-field
      v-model="password"
      outlined
      dense
      color="primary"
      :label="$t('common.password')"
      type= "password"
      required
      :rules="[rules.required, rules.password]" />

    <v-text-field
      v-model="passwordVerify"
      outlined
      dense
      color="primary"
      :label="$t('common.verify_password')"
      type= "password"
      required
      verify-on-blur
      :rules="[rules.verify, rules.required]" />

    <!-- <spacer height="20px"/> -->
    <v-btn color="primary" block type="submit">{{$t('common.register')}}</v-btn>
    <spacer height="10px"/>

    <v-checkbox dense disabled hide-details color="scondary" v-model="tos">
      <template v-slot:label>
        <vue-markdown class="cb-label">
          {{ $t('common.basic_tos', { button_text: buttonText }) }}
        </vue-markdown>
      </template>
    </v-checkbox>

    <v-checkbox dense hide-details color="scondary" v-model="marketing">
      <template v-slot:label>
        <vue-markdown class="cb-label">
          {{ $t('common.basic_adv_marketing') }}
        </vue-markdown>
      </template>
    </v-checkbox>
  </v-form>
</template>

<script type="text/javascript">
import userService from '../user-service'
import rules from '@/common/mixins/validations-rules-mixin.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'signupForm',

  mixins: [rules],

  data () {
    return {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      password: '',
      passwordVerify: '',
      serverErrors: '',
      tos: true,
      marketing: false,
      rules: {
        verify: val => {
          return val === this.password || this.$t('common.unmatched_password')
        }
      },
      buttonText: this.$t('common.register')
    }
  },

  computed: {
    ...mapGetters('user', ['user'])
  },

  methods: {
    ...mapActions('user', ['initUser']),

    async submit () {
      this.serverErrors = ''
      if (this.$refs.form.validate()) {
        try {
          await userService.signup({
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            tos: this.tos,
            marketing: this.marketing,
            phones: [{
              name: 'mobile',
              number: this.mobile
            }],
            password: this.password
          })
          await this.initUser()
          this.$root.$emit('signup')
          this.$mixpanel.track('signup', { action: 'submit', user: this.user.id })
        } catch (e) {
          this.serverErrors = e.data.message
        }
      }
    },

    reset () {
      this.$refs.form.resetValidation()
      this.$refs.form.reset()
      this.serverErrors = ''
    }
  },

  created () {
    this.$mixpanel.track('signup', { action: 'load' })
  }
}

</script>

<style type="text/css" lang="scss">
  form {

    .cb-label {
      text-align: justify;
      font-size: $font-xs;
      color: var(--v-secondary-base);

      p {
        margin: 0;
      }
    }
  }
</style>
