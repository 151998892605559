<template>
  <v-snackbar
    :value="showCookiesSnackbar && !cookiesApproval"
    transition="slide-y-reverse-transition"
    color="bright"
    light
    timeout="-1"
    multi-line>

    <div class="font-16">
      <vue-markdown :anchorAttributes="{target:'_blank'}">{{$t('cookies.snackbar_message')}}</vue-markdown>
    </div>

    <template v-slot:action="{ attrs }">
      <v-btn small color="secondary" @click="setCookiesApproval" v-bind="attrs">
        {{$t('cookies.snackbar_button')}}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {

  name: 'CookiesPolicyMessage',

  data () {
    return {
      showCookiesSnackbar: false
    }
  },

  computed: {
    ...mapGetters(['cookiesApproval'])
  },

  methods: {
    ...mapActions(['setCookiesApproval'])
  },

  created () {
    setTimeout(() => {
      this.showCookiesSnackbar = true
    }, 5000)
  }
}
</script>

<!-- <style lang="scss" scoped>
</style> -->
