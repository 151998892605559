<template>
  <div class="search-slider price-slider">

    <v-container>
      <div class="slider-title" :class="{'theme--light v-input--is-disabled': active}">
        {{ model | currency }}
        <span>({{ $t("search.approximately") }})</span>
      </div>

      <v-row class="align-center" no-gutters>
        <v-switch dense v-model="active" @change="toggle" :ripple="false" hide-details/>
        <!-- <v-btn  icon
                @click="toggle"
                color="primary"
                aria-label="disable price">

          <v-icon :class="{enabled: !active}" color="primary darken-1">mdi-plus-circle-outline</v-icon>
        </v-btn> -->

        <v-slider v-model="model"
                  height="40"
                  :step="step"
                  min="500000"
                  :max="max"
                  :disabled="!active"
                  track-color="primary lighen-1"
                  color="primary darken-1"
                  @end="updateValue()"
                  @click="updateValue()"
                  hide-details/>

      </v-row>
    </v-container>
  </div>
</template>

<script>

import disabledMixin from '@/common/mixins/slider-disabled.js'

export default {
  name: 'PriceSlider',

  props: {
    value: {
      type: [String, Number],
      default: 2000000
    },
    max: {
      type: [String, Number],
      default: 10000000
    },
    step: {
      type: [String, Number],
      default: 100000
    }
  },

  mixins: [disabledMixin],

  methods: {
    initValue () {
      return this.value || 2000000
    }
  }
}

</script>

<style lang="scss" scoped>

  .price-slider {

    .slider-title {

      span {
        font-size: $font-xs;
      }
    }
  }

</style>
