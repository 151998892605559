const stepperMixin = {
  methods: {
    next () {
      this.steps++
    },
    back () {
      this.steps--
    }
  }
}

export default stepperMixin
