<template>
  <div class="free-search">
    <v-row no-gutters class="align-end">
      <slot name="extend"/>
    </v-row>

    <v-text-field
      ref="field"
      name="search"
      dense
      hide-details
      :placeholder="label"
      :class="['serach-field', {'with-btn': !noButton, 'with-filter': !noFilter}]"
      solo
      v-model="searchText"
      @focus="focused"
      @blur="blured"
      @change="changed">

      <template v-slot:append-outer v-if="!noButton">
        <v-btn type="submit" color="primary darken-1" aria-label="search" small>
          <v-icon class="icon-lg">mdi-home-search-outline</v-icon>
        </v-btn>
      </template>

      <template v-slot:prepend>
        <v-overflow-btn class="filter"
                        v-model="dealType"
                        :items="availableDeals"
                        :attach="$refs.menuAttach"
                        @input="changed"
                        background-color="bright"
                        item-color="secondary"
                        flat dense hide-details>
            <template v-slot:append-outer>
              <div ref="menuAttach"></div>
            </template>
        </v-overflow-btn>
      </template>
     </v-text-field>
  </div>
</template>

<script>

export default {
  name: 'FreeSearch',

  props: {
    value: {
      type: Object,
      default () {
        return {
          freeText: '',
          dealType: 'sale'
        }
      }
    },

    noButton: Boolean,
    noFilter: Boolean,

    label: {
      type: String,
      default: function () {
        return this.$t('search.search_label')
      }
    }
  },

  data () {
    return {
      dealType: '',
      availableDeals: [
        { text: this.$t(`search.sale`), value: 'sale' },
        { text: this.$t(`search.rent`), value: 'rent' }
      ],
      searchText: ''
    }
  },

  methods: {
    changed () {
      this.$emit('input', { freeText: this.searchText, dealType: this.dealType })
    },

    focused ($event) {
      this.$emit('focus', $event)
    },

    blured ($event) {
      this.$emit('blur', $event)
    },

    focus () {
      this.$refs.field.focus()
    }
  },

  mounted () {
    this.searchText = this.value.freeText
    this.dealType = this.value.dealType
  }
}
</script>

<style lang="scss">

  .free-search {

    .serach-field {

      button[type="submit"] {
        border-radius: 4px 0 0 4px;
        height: 38px;
      }

      &.v-text-field {

        .v-input__append-outer,
        .v-input__prepend-outer {
          margin: 0;
        }
      }

      .filter {
        margin: 0;

        .v-input__control {
          min-height: 38px;

          > .v-input__slot {
            border: 0;
            width: 101px;
            box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

            html[dir="ltr"] & {
              border-radius: 4px 0 0 4px;
            }

            html[dir="rtl"] & {
              border-radius: 0 4px 4px 0;
            }

            input {
              max-width: 0;
              min-width: 0;
              margin: 0;
            }
          }
        }
      }

      &.with-btn {

        html[dir="ltr"] & {
          border-radius: 4px 0 0 4px;
        }

        html[dir="rtl"] & {
          border-radius: 0 4px 4px 0;
        }
      }

      &.with-filter {

        html[dir="ltr"] & {
          border-radius: 0 4px 4px 0;

          &.with-btn {
            border-radius: 0;
          }
        }

        html[dir="rtl"] & {
          border-radius: 4px 0 0 4px;

          &.with-btn {
            border-radius: 0;
          }
        }
      }
    }
  }

</style>
